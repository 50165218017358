import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import NumberFormat from 'react-number-format';
import '../f-input/f-input.css';

export interface FNumericInputProps extends FInputProps {
  /**
   * if the input allows decimals
   */
  decimal?: boolean;
  /**
   * if the input forces positive values
   */
  positive?: boolean;

  formatted?: boolean;
}

const FNumericInput: React.FunctionComponent<FNumericInputProps> = (props: FNumericInputProps): React.ReactElement => {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));
  let maxValue = -1;
  let userkeyDownDetected = false;

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  });

  React.useEffect((): any => {
    if (props.displayFormat) {
      // If formatCode is not special format then we set a max value...
      if (props.displayFormat['formatCode'] === undefined || (props.displayFormat['formatCode'] && ['/', 'W', 'P'].includes(props.displayFormat['formatCode']) === false)) {
        if (props.displayFormat['nbDigits'] && !props.displayFormat['nbDecimals']) {
          maxValue = Number(''.padStart(props.displayFormat['nbDigits'], '9'));
        } else if (props.displayFormat['nbDigits'] && props.displayFormat['nbDecimals']) {
          maxValue = Number(''.padStart(props.displayFormat['nbDigits'] - props.displayFormat['nbDecimals'], '9'));
          maxValue = Number(String(maxValue) + '.' + ''.padStart(props.displayFormat['nbDecimals'], '9'));
        }
      }
    }
  });

  /* TO REVISE
  const zeroAsBlank = (value: any): any => {
    if (props.displayFormat && props.displayFormat['zeroAsBlank']) {
      if (Number(value) === 0) {
        return '';
      }
    }
    return value;
  };
*/

  // Manage mask based on the FormatCode in displayFormat
  const getFormatMaskFromDisplayFormat = (): any => {
    if (props.displayFormat) {
      if (props.displayFormat['formatCode']) {
        const formatCode = props.displayFormat['formatCode'];

        switch (formatCode) {
          case 'P': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 7) {
              // Phone displayed as 7 digits
              return '###-####';
            } else {
              // Phone displayed with full american digits
              return '###/###-####';
            }
          }
          case 'W': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 6) {
              // Numeric date is yy/mm/dd
              return '##/##/##';
            } else {
              // Numeric date is yyyy/mm/dd
              return '##/##/####';
            }
          }
          case '/': {
            if (props.displayFormat['nbDigits'] && props.displayFormat['nbDigits'] === 8) {
              // Only 8 digits then its a date only
              return '####/##/##';
            } else {
              // else we assume that we have the time part
              return '####/##/##/##:##:##';
            }
          }
        }
      } else if (props.displayFormat['mask']) {
        return props.displayFormat['mask'].replace(/_/g, ' ').replace(/9/g, '#');
      }
    }

    return null;
  };

  const renderLegacyInput = (): React.ReactElement => {
    const format = getFormatMaskFromDisplayFormat();
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-numeric'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <NumberFormat
            format={format}
            value={props.value}
            displayType={'input'}
            thousandSeparator={props.displayFormat && props.displayFormat['thousandSeparator']}
            prefix={props.displayFormat && props.displayFormat['prefix']}
            suffix={props.displayFormat && props.displayFormat['suffix']}
            decimalScale={props.displayFormat && props.displayFormat['nbDigits'] && props.displayFormat['nbDecimals']}
            fixedDecimalScale={true}
            allowLeadingZeros={props.displayFormat && props.displayFormat['padLeft']}
            isAllowed={(values: any): any => maxValue !== -1 ?
              (values.formattedValue === '' || values.floatValue <= maxValue) : true}
            customInput={InputText}
            className={'fp-inputtext'}
            id={props.name}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            disabled={props.protect}
            maxLength={format ? undefined : props.maxlength}
            tooltip={props.tooltip}
            onValueChange={(values: any): void => {
              const { formattedValue, value } = values;
              if (userkeyDownDetected && value !== props.value) {
                userkeyDownDetected = false;
                let numValue;
                if (typeof value === 'string') {
                  numValue = parseFloat(value);
                } else {
                  numValue = value;
                }

                setSuffixValue(baseInput.setSuffix(numValue, props.suffix));
                baseInput.onChange(numValue, props.onValueChange);
              }
            }}
            onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
            onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
            onKeyDown={(e: any): void => {
              userkeyDownDetected = true;
              if (props.onKeyDown) {
                props.onKeyDown(e);
              }
            }}
            keyfilter={getKeyFilter()}
          />
          {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}
          {
            props.promptable &&
            !props.protect &&
            (
              <Button
                className={'promptable-btn'}
                icon="pi pi-search"
                onClick={(e: any): void => {
                  e.preventDefault();
                  baseInput.onPrompt(e, props.onPrompt);
                }}
              />
            )
          }
          {props.required && <span className={'required-symbol'}>*</span>}
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  };

  const renderInput = (): React.ReactElement => {
    const format = getFormatMaskFromDisplayFormat();
    return (
      <React.Fragment>
        <div className={baseInput.getInputWrapperClasses(props) + ' f-numeric'}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          <NumberFormat
            format={format}
            value={props.value}
            displayType={'input'}
            thousandSeparator={props.displayFormat && props.displayFormat['thousandSeparator']}
            prefix={props.displayFormat && props.displayFormat['prefix']}
            suffix={props.displayFormat && props.displayFormat['suffix']}
            decimalScale={props.displayFormat && props.displayFormat['nbDigits'] && props.displayFormat['nbDecimals']}
            fixedDecimalScale={true}
            allowLeadingZeros={props.displayFormat && props.displayFormat['padLeft']}
            isAllowed={(values: any): any => maxValue !== -1 ?
              (values.formattedValue === '' || values.floatValue <= maxValue) : true}
            customInput={InputText}
            className={'fp-inputtext'}
            id={props.name}
            placeholder={props.placeholder}
            readOnly={props.readonly}
            disabled={props.protect}
            maxLength={format ? undefined : props.maxlength}
            tooltip={props.tooltip}
            onValueChange={(values: any): void => {
              const { formattedValue, value } = values;
              if (userkeyDownDetected && value !== props.value) {
                userkeyDownDetected = false;
                let numValue;
                if (typeof value === 'string') {
                  numValue = parseFloat(value);
                } else {
                  numValue = value;
                }
                setSuffixValue(baseInput.setSuffix(value, props.suffix));
                baseInput.onChange(value, props.onValueChange);
              }
            }}
            onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
            onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
            onKeyDown={(e: any): void => {
              userkeyDownDetected = true;
              if (props.onKeyDown) {
                props.onKeyDown(e);
              }
            }}
            keyfilter={getKeyFilter()}
          />
          {props.suffix && <span className={'suffix'}>{suffixValue}</span>}
          {props.promptable && !props.protect &&
            (
              <Button
                className={'promptable-btn'}
                icon="pi pi-search"
                onClick={(e: any): void => {
                  e.preventDefault();
                  baseInput.onPrompt(e, props.onPrompt);
                }}
              />
            )
          }
          {props.required && <span className={'required-symbol'}>*</span>}
        </div>
        {props.error && props.error !== '' &&
          <span className={'error-msg'}>{props.error}</span>
        }
      </React.Fragment>
    );
  };

  /**
   * Format appropriate primeNg keyFilter to match decimal and positive attributes
   */
  const getKeyFilter = (): string => {
    const decimalFilter = props.decimal ? 'num' : 'int';
    const positiveFilter = props.positive ? 'p' : '';

    return positiveFilter + decimalFilter;
  };

  if (props.legacy) {
    return renderLegacyInput();
  }
  return renderInput();
};

// Set default props
FNumericInput.defaultProps = {
  decimal: true,
  positive: false,
};

export default FNumericInput;
