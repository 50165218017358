import { getInputWrapperClasses } from '@fresche/common-lib';

export interface FInputProps {
  /**
   * name attribute of the input
   */
  name?: string;
  /**
   * unique identifier of the input
   */
  id: string;
  /**
   * value attribute of the input
   */
  value?: any;
  /**
   * maxlength attribute of the input
   */
  maxlength?: number;
  /**
   * label attribute of the input
   */
  label?: string;
  /**
   * if label if always visible
   */
  alwaysVisibleLabel?: boolean;
  /**
   * label position
   */
  labelOnTop?: boolean;
  /**
   * placeholder attribute of the input
   */
  placeholder?: string;
  className?: string;
  /**
   * suffix attribute of the input
   * @example
   * the value can be either a string, or an object like this:
   * {'key1': 'value1', 'key2': 'value2'};
   * an object will allow contextual value of suffix based on input value.
   */
  suffix?: any;
  /**
   * cols attribute of the input
   */
  cols?: number;
  /**
   * offset attribute of the input
   */
  offset?: number;
  /**
   * invalid attribute of the input. error message will appear based on this value
   */
  invalid?: boolean;
  /**
   * promptable attribute of the input. popup will apear on click of a button if true.
   */
  promptable?: boolean;
  /**
   * required attribute of the input. * will apear on the right if true.
   */
  required?: boolean;
  /**
   * error message
   */
  error?: string;
  /**
   * focuses this input automatically
   */
  autofocus?: boolean;
  /**
   * if input is displayed with legacy layout
   */
  legacy?: boolean;
  /**
   * readonly attribute of the input
   */
  readonly?: boolean;
  /**
   * tooltip attribute of the input
   */
  tooltip?: string;
  /**
   * position of the tooltip, valid values are right, left, top and bottom
   */
  tooltipPosition?: string;
  /**
   * style class of the tooltip
   */
  tooltipStyleClass?: string;
  /**
   * event to show the tooltip, valid values are hover and focus.
   */
  tooltipEvent?: string;
  /**
   * valuechange Event Emitter for 2 way binding
   */
  onValueChange?: (newValue: any) => any;
  /**
   * onKeyDown Event Emitter for 2 way binding
   */
  onKeyDown?: (e: Event) => any;
  /**
   * onPrompt Event Emitter for 2 way binding
   */
  onPrompt?: (e: any) => any;
  /**
   * onFocus Event Emitter for 2 way binding
   */
  onFocus?: (ev: any) => any;
  /**
   * onBlur Event Emitter for 2 way binding
   */
  onBlur?: (ev: any) => any;

  /**
   * display format attributes
   */
  displayFormat?: object;

  // TODO: create Conditions interface like in the Angular Lib
  color?: string;
  blinking?: boolean;
  highintensity?: boolean;
  reverseimage?: boolean;
  underline?: boolean;
  protect?: boolean;
  hidden?: boolean;
}

export default class FInput {

  public getInputWrapperClasses = getInputWrapperClasses;

  /**
   * Focuses the HTML input element
   */
  focusElement(id: string): void {
    const el = document.getElementById(id);
    if (el) {
      el.focus();
    }
  }

  /**
   * Focuses the HTML input element
   */
  setSuffix(value: string, suffix?: any): any {
    // If input has a suffix that changes with the value of its content
    if (suffix) {
      if (typeof suffix === 'object') {
        return suffix[value.toString()];
      } else if (typeof suffix === 'string') {
        return suffix;
      }
    }
    return '';
  }

  /**
   * Component onchange event
   * @param newValue the new value of the component
   * @param onValueChange function to call on value change
   */
  onChange(newValue: string, onValueChange: any): void {
    if (onValueChange && typeof onValueChange === 'function') {
      onValueChange(newValue);
    }
  }

  /**
   * Component onFocus event
   * @param ev onFocus event element
   * @param onFocus function to call on focus
   */
  onFocus(ev: any, onFocus: any): void {
    // Select the text in the input if available
    if (ev && ev.target && typeof ev.target.setSelectionRange === 'function') {
      ev.target.setSelectionRange(0, ev.target.value.length);
    }
    // Emit the focus event
    if (onFocus !== undefined && typeof onFocus === 'function') {
      onFocus(ev);
    }
  }

  /**
   * Component onLur event
   * @param ev onBlur event element
   * @param onBlur function to call on blur
   */
  onBlur(ev: any, onBlur: any): void {
    // Emit the focus event
    if (onBlur !== undefined && typeof onBlur === 'function') {
      onBlur(ev);
    }
  }

  /**
   * Component onPrompt event
   * @param ev onPrompt event element
   * @param onPrompt function to call on prompt
   */
  onPrompt(e: any, onPrompt: any): void {
    if (onPrompt !== undefined && typeof onPrompt === 'function') {
      onPrompt(e);
    }
  }
}
