import * as React from 'react';
import { Button } from 'primereact/button';
import './f-continue-button.css';

export interface FContinueButtonProps {
    id: string;
    title: string;
    disabled: boolean;
    handleContinue: () => any;
}

export default function FContinueButton(props: FContinueButtonProps): React.ReactElement {
    React.useEffect((): any => {
        document.addEventListener('keydown', handleContinue, false);
        return function cleanup(): void {
            document.removeEventListener('keydown', handleContinue, false);
        };
    });

    const handleContinue = (e: any): void => {
        if ((e.type === 'click' || (e.type === 'keydown' && e.key === 'Enter')) && !props.disabled) {
            props.handleContinue();
        }
    };

    return (
        <Button id={props.id} label={props.title} disabled={props.disabled} onClick={handleContinue} />
    );
}
