import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';
import { InputText } from 'primereact/inputtext';
import { InputMask } from 'primereact/inputmask';
import { Button } from 'primereact/button';
import '../f-input/f-input.css';

export default function FTextInput(props: FInputProps): React.ReactElement {
  const baseInput = new FInput();
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));

  React.useEffect((): any => {
    if (props.autofocus) {
      setTimeout(() => {
        baseInput.focusElement(props.id);
      }, 100);
    }
  }, []);

  const onChange = (val: any): void => {
    if (props.displayFormat && props.displayFormat['textCase'] === 'uppercase') {
      val = String(val).toUpperCase();
    }
    setSuffixValue(baseInput.setSuffix(val, props.suffix));
    baseInput.onChange(val, props.onValueChange);
  };

  // Manage mask based on the FormatCode in displayFormat
  const getFormatMaskFromDisplayFormat = (): any => {
    if (props.displayFormat && props.displayFormat['mask']) {
      return props.displayFormat['mask'].replace(/_/g, ' ').replace(/A/g, 'a');
    }

    return null;
  };

  const renderLegacyInput = (): React.ReactElement => {
    const mask = getFormatMaskFromDisplayFormat();

    // Necessary because of bug prime react that cannot accept null nask
    if (mask) {
      return (
        <React.Fragment>
          <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
            {props.label && <label htmlFor={props.name}>{props.label}</label>}
            <InputMask
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              mask={mask}
              unmask={true}
              readonly={props.readonly}
              disabled={props.protect}
              maxlength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
            />
            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}
            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
          {props.error && props.error !== '' &&
            <span className={'error-msg'}>{props.error}</span>
          }
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
            {props.label && <label htmlFor={props.name}>{props.label}</label>}
            <InputText
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              readOnly={props.readonly}
              disabled={props.protect}
              maxLength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
              onKeyDown={(e: any): void => {
                if (props.onKeyDown) {
                  props.onKeyDown(e);
                }
              }}
            />
            {props.suffix !== undefined && <span className={'suffix'}>{suffixValue}</span>}
            {
              props.promptable &&
              !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
          {props.error && props.error !== '' &&
            <span className={'error-msg'}>{props.error}</span>
          }
        </React.Fragment>
      );
    }
  };

  const renderModern = (): React.ReactElement => {
    const mask = getFormatMaskFromDisplayFormat();

    // Necessary because of bug prime react that cannot accept null nask
    if (mask) {
      return (
        <div>
          <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
            {props.label && <label htmlFor={props.name}>{props.label}</label>}
            <InputMask
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              mask={mask}
              unmask={true}
              readonly={props.readonly}
              disabled={props.protect}
              maxlength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
            />
            {props.suffix && <span className={'suffix'}>{suffixValue}</span>}
            {props.promptable && !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
          {props.error && props.error !== '' &&
            <span className={'error-msg'}>{props.error}</span>
          }
        </div >
      );
    } else {
      return (
        <div>
          <div className={baseInput.getInputWrapperClasses(props) + ' f-text'}>
            {props.label && <label htmlFor={props.name}>{props.label}</label>}
            <InputText
              className={props.displayFormat && props.displayFormat['textCase'] === 'uppercase' ? 'fp-inputtext uppercase' : 'fp-inputtext'}
              id={props.name}
              value={props.value ? props.value : ''}
              placeholder={props.placeholder}
              readOnly={props.readonly}
              disabled={props.protect}
              maxLength={props.displayFormat && props.displayFormat['textLength']
                ? props.displayFormat['textLength'] : props.maxlength}
              tooltip={props.tooltip}
              /* tooltipPosition={props.tooltipPosition === undefined ? 'bottom' : props.tooltipPosition}
              tooltipStyleClass={props.tooltipStyleClass}
              tooltipEvent={props.tooltipEvent === undefined ? 'hover' : props.tooltipEvent} */
              onChange={(e: any): void => { onChange(e.target.value); }}
              onFocus={(e: any): void => baseInput.onFocus(e, props.onFocus)}
              onBlur={(e: any): void => baseInput.onBlur(e, props.onBlur)}
              onKeyDown={(e: any): void => {
                if (props.onKeyDown) {
                  props.onKeyDown(e);
                }
              }}
            />
            {props.suffix && <span className={'suffix'}>{suffixValue}</span>}
            {props.promptable && !props.protect &&
              (
                <Button
                  id={props.name + '-promptable'}
                  className={'promptable-btn'}
                  icon="pi pi-search"
                  onClick={(e: any): void => {
                    e.preventDefault();
                    baseInput.onPrompt(e, props.onPrompt);
                  }}
                />
              )
            }
            {props.required && <span className={'required-symbol'}>*</span>}
          </div>
          {props.error && props.error !== '' &&
            <span className={'error-msg'}>{props.error}</span>
          }
        </div >
      );
    }
  };

  if (props.legacy) {
    return renderLegacyInput();
  }
  return renderModern();
}
