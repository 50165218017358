import * as React from 'react';
import { DateLogic } from '@fresche/common-lib';
import FInput, { FInputProps } from '../f-input/f-input';
import '../f-input/f-input.css';
import './f-date-time-input.css';
import moment from 'moment';

// Prime
import { Button } from 'primereact/button';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputMask } from 'primereact/inputmask';
import { Calendar } from 'primereact/calendar';

export interface FDateTimeInputProps extends FInputProps {
  type: string;
  dateFormat?: string;
  timeSeparator?: string;
  showSeconds?: boolean;
  placeholder?: string;
  showOnFocus?: boolean;
  useCalendar?: boolean;
  className?: string;
}

export default function FDateTimeInput(props: FDateTimeInputProps): React.ReactElement {
  /**
   * @ignore
   */
  const baseInput = new FInput();
  /**
   * @ignore
   */
  let calendarOverlay: any;
  /**
   * @ignore
   */
  let overlayTarget: any;
  /**
   * @ignore
   */
  const [calendarDate, setCalendarDate] = React.useState(new Date());
  /**
   * @ignore
   */
  const [mask, setMask] = React.useState('');
  /**
   * Manages date & time logic (from common library)
   */
  const dateLogic = new DateLogic();
  /**
   * Result suffix value based on suffix attribute
   */
  const [suffixValue, setSuffixValue] = React.useState(baseInput.setSuffix(props.value, props.suffix));
  /**
   * Format of the date (YYYY-MM-DD by default)
   */
  const [dateFormat, setDateFormat] = React.useState(props.dateFormat ? props.dateFormat.toUpperCase() : dateLogic.getDefaultDateFormat());
  /**
   * Format of the time (HH:mm:ss by default)
   */
  const [timeFormat, setTimeFormat] = React.useState('HH:mm:ss');
  /**
   * Time Seperator
   */
  const [timeSeparator] = React.useState(props.timeSeparator || ':');
  /**
   * Show seconds or not in time
   */
  const [showSeconds] = React.useState(props.showSeconds || true);
  /**
   * Use the calendar popup or not
   */
  const [useCalendar] = React.useState(() => {
    return typeof props.useCalendar === 'boolean' ? props.useCalendar : true;
  });
  /**
   * Error message
   */
  const [error, setError] = React.useState(props.error);
  /**
   * Displayed date of the component
   */
  const [date, setDate] = React.useState('');

  /**
   * Called when the component loads and when somes properties changed (props.value, dateFormat, timeFormat)
   */
  React.useEffect((): any => {
    const _type = ['time', 'date', 'datetime'].indexOf(props.type) !== -1 ? props.type : 'date';
    const _dateFormat = props.dateFormat ? props.dateFormat.toUpperCase() : dateLogic.getDefaultDateFormat();
    const _displayFormat = props.displayFormat ? props.displayFormat : {};

    let tmpvalue = props.value;

    if (props.displayFormat && props.displayFormat['mask']) {
      //
      // Date with a MASK
      //
      setDateFormat(props.displayFormat['mask'].toUpperCase());

      const maskEditableCharCount = (props.displayFormat['mask'].match(/0/g) || []).length + (props.displayFormat['mask'].match(/9/g) || []).length;
      tmpvalue = tmpvalue ? tmpvalue.toString() : '';
      while (tmpvalue.length < maskEditableCharCount) {
        tmpvalue = '0' + tmpvalue;
      }
      setDate(tmpvalue);
    } else {
      //
      // Normal mode
      //
      setDateFormat(dateLogic.manageDateFormat(_dateFormat, _displayFormat));
      setTimeFormat(dateLogic.manageTimeFormat(timeFormat, timeSeparator, showSeconds, tmpvalue));
      setDate(dateLogic.displayInitialDate(tmpvalue, _type, dateFormat, timeFormat, showSeconds));
    }

    setMask(dateLogic.constructMask(_type, dateFormat, timeFormat, _displayFormat).replace(/[0]/g, '9'));
    setError(props.error);
  }, [props.value, date, mask, dateFormat, timeFormat, props.displayFormat, props.error]);

  /**
   * Called when the input is focused out.
   * @param event blur event of the input
   */
  const onInputComplete = (_event: any): any => {
    /*    if (typeof event.target.value !== 'string') {
      return;
    }
    const newValue = event.target.value;
    if (newValue.length === mask.length || newValue === '') {
      validateDate(newValue);
    }
    }*/
  };

  const onInputChange = (event: any): void => {
    if (typeof event.target.value !== 'string') {
      return;
    }
    const newValue = event.target.value;
    if (newValue.length === mask.length || newValue === '') {
      validateDate(newValue);
    }
  };

  /**
   * Event called when the PrimeNG overlay panel opens.
   * Creates a MomentJS object with the current value of the
   * component and uses it to set 'calendarDate' to a Javascript Date object.
   */
  const onCalendarOpen = (): void => {
    if (props.value && props.value !== dateLogic.getEmptyValue(props.type)) {
      let _date;
      if (props.displayFormat && props.displayFormat['mask']) {
        _date = moment(props.value, dateFormat);
      } else {
        _date = dateLogic.IBMToMomentDate(props.value, props.type);
      }
      if (_date.isValid()) {
        setCalendarDate(_date.toDate());
      }
    } else {
      setCalendarDate(new Date());
    }
  };

  /**
   * Receives a Date and affects values of the component.
   * @param dateObject Javascript Date object
   */
  const onCalendarSelect = (event: any): void => {
    const dateObject = new Date(event.value);
    const tmpdate = moment(dateObject);
    if (tmpdate.isValid()) {
      const newDate = dateLogic.momentToDisplay(tmpdate, dateFormat, timeFormat, showSeconds, props.type);
      setDate(newDate);

      let tmpValue;
      if (props.displayFormat && props.displayFormat['mask']) {
        tmpValue = newDate.replace(/[^0-9]/g, '');
      } else {
        tmpValue = dateLogic.toIBMFormat(props.type, tmpdate);
      }

      baseInput.onChange(tmpValue, props.onValueChange);
      if (props.type === 'date') {
        calendarOverlay.hide();
      }
    }
  };

  /**
   * Called when the 'clear' button is clicked in the PrimeNG Calendar.
   * Empty input and set value to the IBM 'empty value'.
   */
  const onClearButtonClick = (): void => {
    const _value = dateLogic.getEmptyValue(props.type);
    setDate(' ');
    baseInput.onChange(_value, props.onValueChange);
    calendarOverlay.hide();
  };

  /**
   * Returns a string of CSS classes based on the components props.
   */
  const getClasses = (): any => {
    const _props = Object.assign({}, props);
    _props.error = error;
    const classes = baseInput.getInputWrapperClasses(_props);
    return classes + ' f-text ' + props.className;
  };

  /**
   * Validates the date by calling 'validateDate' from date logic (common lib).
   * @param value date value
   */
  const validateDate = (dateToValidate: string): void => {
    if (dateToValidate !== '') {
      if (validDateLength(dateToValidate)) {
        const validDate = dateLogic.validateDate(dateToValidate, props.type, timeSeparator, showSeconds, dateFormat);
        if (validDate) {
          const tmpdate = dateLogic.valueToMoment(dateToValidate, dateFormat, props.type);

          let tmpValue;
          if (props.displayFormat && props.displayFormat['mask']) {
            setDate(tmpdate.format(dateFormat));
            tmpValue = date.replace(/[^0-9]/g, '');
          } else {
            setDate(dateToValidate);
            tmpValue = dateLogic.toIBMFormat(props.type, tmpdate);
          }

          // Check if value hasn't changed
          const isSameValue = (tmpValue === props.value);
          if (!isSameValue) {
            baseInput.onChange(tmpValue, props.onValueChange);
          }
          // Unset error
          setError(undefined);
        } else {
          setError('Invalid ' + props.type);
        }
      } else {
        setError('Invalid date length ' + props.type);
      }
    } else {
      const emptyValue = dateLogic.getEmptyValue(props.type);
      setDate(dateToValidate);
      baseInput.onChange(emptyValue, props.onValueChange);
      // Unset error if empty string is provided
      setError(undefined);
    }
  };

  const validDateLength = (dateStr: string): boolean => {
    if (dateStr) {
      // Keep the value without modifications
      const originalValue = dateStr.toString();
      // Check if the years are at the end of the date format
      const dateAtEnd = dateFormat.slice(-1).toLowerCase() === 'y';
      // Get date separator
      const dateSeparator = dateLogic.getDateSeparator();
      // Remove date separator from string
      dateStr = dateStr.split(dateSeparator).join('');

      if (dateAtEnd) {
        /**
         * If the years are at the end, we accept 'years' as a two or four number (ex: 20 or 2020).
         * Therefore, the date is valid if the value has (minus the date separator) 6 or 8 characters (12-02-20 or 12-02-2020)
         */
        if (props.type === 'date') {
          return dateStr.length === 6 || dateStr.length === 8;
        } else if (props.type === 'datetime') {
          // If the type is 'datetime', we allow a value that is more than 8 characters
          return dateStr.length === 6 || dateStr.length >= 8;
        }
      } else {
        /**
         * If the years are not at the end, we only accept a value that has the same number of characters
         * than the provided date format (ex: 'YYYY-MM-DD' -> 2020-02-12).
         */
        if (props.type === 'date') {
          return dateFormat.length === originalValue.length;
        } else if (props.type === 'datetime') {
          // If the type is 'datetime', we allow a value that is more than 8 characters
          return originalValue.length >= dateFormat.length;
        }
      }
    } else {
      return false;
    }
    return true;
  };

  /**
   * Renders the input.
   */
  const renderInput = (): React.ReactElement => {
    return (
      <InputMask
        mask={mask}
        slotChar={''}
        unmask={false}
        id={props.name}
        value={date}
        placeholder={props.placeholder}
        readonly={props.readonly}
        disabled={props.protect ? true : false}
        tooltip={props.tooltip}
        onComplete={(e: any): void => { onInputComplete(e); }}
        onChange={(e: any): void => { onInputChange(e); }}
      />
    );
  };

  /**
   * Renders the calendar overlay.
   */
  const renderOverlay = (): React.ReactElement => {
    return (
      <OverlayPanel ref={(el: any): void => calendarOverlay = el} className={'calendar-overlay'}>
        <Calendar
          value={calendarDate}
          onSelect={(e: any): void => { onCalendarSelect(e); }}
          showSeconds={showSeconds}
          showTime={props.type !== 'date'}
          timeOnly={props.type === 'time'}
          inline={true}
          showWeek={false}
          showButtonBar={true}
          monthNavigator={true}
          yearNavigator={true}
          yearRange="1900:2100"
          todayButtonClassName={props.type === 'time' ? 'hidden' : ''}
          onClearButtonClick={(): void => { onClearButtonClick(); }}
        />
      </OverlayPanel>
    );
  };

  /**
   * Renders the calendar button.
   */
  const renderCalendarButton = (): React.ReactElement => {
    return (
      <Button
        type="button"
        id={props.name + '-promptable'}
        className={'calendar-toggle-btn'}
        icon="pi pi-calendar"
        onClick={(e: any): void => {
          e.preventDefault();
          e.stopPropagation();
          calendarOverlay.toggle(e, overlayTarget);
          onCalendarOpen();
        }}
      />
    );
  };

  /**
   * Render the component
   */
  const renderModern = (): React.ReactElement => {
    return (
      <React.Fragment>
        {useCalendar && renderOverlay()}
        <div className={getClasses()}>
          {props.label && <label htmlFor={props.name} ref={(el: any): void => overlayTarget = el}>{props.label}</label>}
          {renderInput()}
          {useCalendar && !props.readonly && !props.protect && renderCalendarButton()}
          {props.suffix && <span className={'suffix'}>{suffixValue}</span>}
          {props.required && <span className={'required-symbol'}>*</span>}
        </div>
        {error && error !== '' && <span className={'error-msg'}>{error}</span>}
      </React.Fragment>
    );
  };

  /**
   * Render the component in 'legacy' mode
   */
  const renderLegacy = (): React.ReactElement => {
    return (
      <React.Fragment>
        {useCalendar && renderOverlay()}
        <div className={getClasses()}>
          {props.label && <label htmlFor={props.name}>{props.label}</label>}
          {renderInput()}
          {useCalendar && !props.readonly && !props.protect && renderCalendarButton()}
          {props.suffix && <span className={'suffix'}>{suffixValue}</span>}
          {props.required && <span className={'required-symbol'}>*</span>}
        </div>
        {error && error !== '' && <span className={'error-msg'}>{error}</span>}
      </React.Fragment>
    );
  };

  // Render the component based on the 'legacy' value
  if (props.legacy) {
    return renderLegacy();
  }
  return renderModern();
}
