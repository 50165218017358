import * as React from 'react';
import { Button } from 'primereact/button';
import './f-action-button.css';

export interface FActionButtonProps {
    title?: string;
    tooltip?: string;
    icon?: string;
    legacy: boolean;
    disabled: boolean;
    condition?: object;
    className?: string;
    action: () => any;
}

export default function FActionButton(props: FActionButtonProps): React.ReactElement {
    if (props.legacy) {
        return (
            <Button
                className={`legacy-action-button ${props.className}`}
                label={props.title ? props.title : ''}
                disabled={props.disabled}
                onClick={!props.disabled ? props.action : (): void => { }}
            />
        );
    }
    return (
        <Button
            className={`action-button ${props.className}`}
            tooltip={props.tooltip ? props.tooltip : ''}
            disabled={props.disabled}
            icon={props.icon}
            onClick={!props.disabled ? props.action : (): void => { }}
            tooltipOptions={{ position: 'bottom' }}
        />
    );
}
