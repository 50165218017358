import * as React from 'react';
import FInput, { FInputProps } from '../f-input/f-input';

export interface FLabelProps extends FInputProps {
    text: string;
}

export default function FLabel(props: FLabelProps): React.ReactElement {
    const baseInput = new FInput();

    return (
        <div className={baseInput.getInputWrapperClasses(props)}>
            <label className="orphan-label">{props.text}</label>
        </div>
    );
}
